@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.13, 1.13, 1.13);
      transform: scale3d(1.13, 1.13, 1.13);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  @keyframes pulse {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  
    50% {
      -webkit-transform: scale3d(1.13, 1.13, 1.13);
      transform: scale3d(1.13, 1.13, 1.13);
    }
  
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
  
  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }

  @keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    from {
      opacity: 0;
      transform: translate3d(0, 3000px, 0);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(0, -20px, 0);
    }
  
    75% {
      transform: translate3d(0, 10px, 0);
    }
  
    90% {
      transform: translate3d(0, -5px, 0);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .bounceInUp {
    animation-name: bounceInUp;
  }

  @keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0);
    }
  
    75% {
      transform: translate3d(0, -10px, 0);
    }
  
    90% {
      transform: translate3d(0, 5px, 0);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .bounceInDown {
    animation-name: bounceInDown;
  }

  @keyframes bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: translate3d(-3000px, 0, 0);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(25px, 0, 0);
    }
  
    75% {
      transform: translate3d(-10px, 0, 0);
    }
  
    90% {
      transform: translate3d(5px, 0, 0);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .bounceInLeft {
    animation-name: bounceInLeft;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  .animated.delay-03s {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  
  .animated.delay-05s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  
  .animated.fast {
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
  }
  
  .animated.faster {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
  }
  
  .animated.slow {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .animated.slower {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
  }
  
  @media (print), (prefers-reduced-motion: reduce) {
    .animated {
      -webkit-animation-duration: 1ms !important;
      animation-duration: 1ms !important;
      -webkit-transition-duration: 1ms !important;
      transition-duration: 1ms !important;
      -webkit-animation-iteration-count: 1 !important;
      animation-iteration-count: 1 !important;
    }
  }  