@import "bourbon/bourbon";
@import "animate";

$cursive: 'Dancing Script', cursive;

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

$main_color: #870f16;

h1 {
    font-size: 1.3em;
    color: $main_color !important;
    text-align: center;
    padding: 1em 1.8em 1.4em;
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

body {
    background-color: $main_color;
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    box-sizing: border-box;
    padding: 0 1.25em;
    color: darken($main_color, 15%);    
}

.image {
    background-image: url('/assets/img/home-hero.jpg');
    opacity: .1;
    mix-blend-mode: multiply;
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 220;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
    filter: gray;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}

.wrapper {
    position: relative;
    z-index: 1000;
    max-width: 600px;
    margin: 0 auto 5em;
    box-shadow: 0px 20px 48px rgba(0,0,0,.4);
    .menu {
        background-color: #e6e1e1;
        border-radius: 6px 6px 0 0;
        font-size: 1.05em;
        position: relative;
    }

    ul {
        padding: 1.8rem;
        li {
            margin-bottom: 1.5em;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .info {
        line-height: 1.4;
        font-weight: 500;
        font-size: .666em;
        padding: 1.8rem 1.8rem 1rem;
        color: rgba($color: darken($main_color, 10%), $alpha: .5);
    }
}

.back-nav {
    position: fixed;
    z-index: 9000;
    top: 0;
    left: 0;
    padding: 30px 50px;
    .back {
        text-decoration: none;
    }
    .back:hover{
        .arrow {
            background-color: rgba($color: #fff, $alpha: .4);
        }
        .logo {
            opacity: 1;
        }
    }
    .arrow {
        padding: 12px;
        border-radius: 100px;
        margin-top: 5px;
        -webkit-transition: background-color .5s; /* Safari prior 6.1 */
        transition: background-color .5s;
        position: absolute;
        left: -2em;
    }
    .logo {
        padding-left: 1.5rem;
        opacity: .8;
        -webkit-transition: opacity .5s; /* Safari prior 6.1 */
        transition: opacity .5s;
        position: relative;
    }
}

.hero {
    text-align: center;
    margin-bottom: 0;
    margin-top: -2rem;
    position: relative;
    z-index: 210;
}

.translation {
    font-family: 'Raleway', sans-serif;
    font-style: italic;
    font-size: .8em;
    color: #7e6d6d;
    padding-top: 8px;
    font-weight: 500;
}

.price { 
    text-align: center;
    color: $main_color;
    font-size: 1.10em;
}

.btn {
    border-radius: 4px;
    padding: 10px;
    color: white;
    margin: .6rem 0 .6rem .6rem;
    font-size: .8em;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
}

.btn.green {
background: #b0dd3e; /* Old browsers */
background: -moz-linear-gradient(top,  #b0dd3e 0%, #808e3a 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #b0dd3e 0%,#808e3a 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #b0dd3e 0%,#808e3a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b0dd3e', endColorstr='#808e3a',GradientType=0 ); /* IE6-9 */

    &:hover{
        background: #b0dd3e; /* Old browsers */
        filter: none;
    }
}

.btn.blue {
background: #a90329; /* Old browsers */
background: -moz-linear-gradient(top,  #a90329 0%, #6d0019 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #a90329 0%,#6d0019 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #a90329 0%,#6d0019 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019',GradientType=0 ); /* IE6-9 */
    &:hover {
        background: #a90329; /* Old browsers */
        filter: none;
    }
}

.action{
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    padding: 1rem 1.6rem;
    // position: sticky;
    bottom: 0;
    max-width: 600px;
    z-index: 1000;
    background-color: rgba($color: darken($main_color, 15%), $alpha: .95);
    color: #d8d8d8;
    font-size: 14px;
    border-radius: 0 0 6px 6px;
    margin: 0 auto;
    text-align: center;
    span {
        display: inline-block;
    }
}

.slick-next::before, .slick-prev::before {
    color: darken($main_color, 10%);
}

.slick-next, .slick-prev {
    top: 46px;
    z-index: 1000;
}

.slick-prev {
    left: initial;
    right: 90px;
}

.slick-next {
    left: initial;
    right: 8px;
}

@media only screen and (max-width: 829px) {
    body {
        font-size: 14px;
    }
    .back-nav {
        position: absolute;
        // padding: 20px;
    }

    // .hero {
    //     margin-top: 3rem;
    // }

    .lights.two {
        display: none;
    }

    .action {
        text-align: center;
    }

    h1 {
        text-align: left;
    }

    .slick-prev {
        right: 80px;
        top: 40px;
    }

    .slick-next {
        right: 4px;
        top: 40px;
    }
}

.calendar {
    position: absolute;
    right: 2em;
    top: 0;
}

time.icon
{
  font-size: .5em; /* change icon size */
  display: block;
  position: relative;
  width: 6em;
  height: 6.3em;
  background-color: #fff;
  margin: 2em auto;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #a0a0a0, 0 2px 0 #fff, 0 3px 0 #a0a0a0, 0 4px 0 #fff, 0 5px 0 #a0a0a0, 0 0 0 1px #a0a0a0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: $main_color;
  border-bottom: 1px dashed darken($main_color, 5%);
  box-shadow: 0 2px 0 $main_color;
}

time.icon em
{
  position: absolute;
  bottom: 0.3em;
  color: $main_color;
}

time.icon span
{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.8em;
  color: #2f2f2f;
}

.no-js {
    .wrapper {
        margin-bottom: 0;
        box-shadow: none;
    }
    .menu-item {
        box-shadow: 0px 20px 48px rgba(0,0,0,.4);
        margin-bottom: 5em;
    }
}

.noise {
    background-image: url('/assets/img/noise.png');
    background-repeat: repeat;
    mix-blend-mode: multiply;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .4;
    z-index: 300;
    background-size: 200px 200px;
}

.lights.one {
    background-image: url('/assets/img/lights.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
    background-color: $main_color;
    background-size: 362px+50px 384px+50px;
}

.lights.two {
    background-image: url('/assets/img/lights.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    right: 0;
     bottom: 0;
    left: 0;
    z-index: 210;
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
    background-size: 362px 384px;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) { 
    .noise {
        background-image: url('/assets/img/noise.png');
        background-repeat: repeat;
        mix-blend-mode: multiply;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .4;
        z-index: 300;
        background-size: 200px 200px;
    }

    .lights.one {
        background-image: url('/assets/img/lights.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 200;
        background-size: 362px+50px 384px+50px;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        background-color: $main_color;
    }

    .lights.two {
        background-image: url('/assets/img/lights.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        position: fixed;
        top: 0;
        right: 0;
         bottom: 0;
        left: 0;
        z-index: 210;
        background-size: 362px 384px;
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
        
    }
}

.slick-slider {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  
.optional {
    margin-top: -.75rem;
    margin-bottom: .75rem;
    text-align: center;
}